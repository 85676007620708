import styled from 'styled-components'
import { Col, Row, Button, Divider } from 'antd'
import {
  darkBlue1,
  lightGreen2,
  greenDark1,
  grey,
  themeColor,
  mobileWidthMax,
  greenDark2,
  extraDesktopWidthMax,
  mediumDesktopExactWidth,
  smallDesktopWidth,
  largeDesktopWidth,
} from '@edulastic/colors'
import { MAX_TAB_WIDTH } from '../../../author/src/constants/others'

export const FlexWrapper = styled(Row)`
  @media (max-width: ${mobileWidthMax}) {
    flex-direction: column;
  }
`

export const RegistrationWrapper = styled.div`
  position: relative;
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  background: url(${(props) => props.image});
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
`

export const RightContainer = styled.div`
  min-width: ${({ windowWidth }) =>
    windowWidth > MAX_TAB_WIDTH ? '50%' : '100%'};
  max-width: ${({ windowWidth }) =>
    windowWidth > MAX_TAB_WIDTH ? '50%' : '100%'};
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  padding: 20px;
`

export const RegistrationHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 20px;
  span {
    font-size: 14px;
    font-weight: bold;
    margin-right: 12px;
  }
  a {
    padding: 7px 25px;
    text-decoration: none;
    color: white;
    border-radius: 18px;
    background: ${themeColor};
    font-size: 14px;
    font-weight: bold;
  }
`

export const BannerText = styled(Col)`
  color: white;
  h1 {
    color: white;
    line-height: 1.3;
    letter-spacing: -2px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 15px;
    @media (min-width: ${smallDesktopWidth}) {
      font-size: 26px;
    }
    @media (min-width: ${mediumDesktopExactWidth}) {
      font-size: 40px;
    }
    @media (min-width: ${extraDesktopWidthMax}) {
      font-size: 60px;
    }
  }
  a {
    font-size: 11px;
    margin-top: 10px;
    font-weight: 600;
    color: white;
  }
  div {
    font-size: 13px;
    margin-top: 10px;
  }

  @media (max-width: ${mobileWidthMax}) {
    h1 {
      font-size: 35px;
      text-align: center;
    }
  }
`

export const RegistrationBody = styled(Row)`
  min-height: calc(100vh - 120px);
  padding: 15px;
  @media (max-width: ${mobileWidthMax}) {
    padding: 20px;
  }
`

export const Copyright = styled(Row)`
  color: ${grey};
  text-align: center;
  margin: 20px 0px;
  @media (max-width: ${smallDesktopWidth}) {
    font-size: 10px;
  }
  @media (min-width: ${mediumDesktopExactWidth}) {
    font-size: 11px;
  }
`

export const FormWrapper = styled.div`
  background: white;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  z-index: 1;
`

export const FormHead = styled(Row)`
  background: ${darkBlue1};
  background: ${`-moz-radial-gradient(ellipse at center, ${lightGreen2} 16%, ${greenDark1} 100%)`};
  background: ${`-webkit-radial-gradient(ellipse at center,  ${lightGreen2} 16%, ${greenDark1} 100%)`};
  background: ${`radial-gradient(ellipse at center, ${lightGreen2} 16%, ${greenDark1} 100%)`};
  padding: 15px;
  h3 {
    color: white;
    margin: 5px 0px 15px;
    font-size: 18px;

    @media (min-width: ${mediumDesktopExactWidth}) {
      font-size: 22px;
    }
    @media (min-width: ${extraDesktopWidthMax}) {
      font-size: 26px;
    }
  }
`

export const SignUpContainer = styled.div`
  max-width: 420px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80%;
  background: ${({ isDistrictSignup }) =>
    isDistrictSignup ? 'white' : 'none'};
  padding: ${({ isDistrictSignup }) =>
    isDistrictSignup ? '40px 45px' : 'none'};
  border-radius: ${({ isDistrictSignup }) =>
    isDistrictSignup ? '6px' : 'none'};
`

export const ThirdPartyLoginBtn = styled.div`
  background: white;
  border: 1px solid #d8d8d8;
  border-radius: 30px;
  text-align: center;
  font-size: 10px;
  padding: 0px 28px;
  height: 51px;
  cursor: pointer;
  margin-bottom: 13px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  img {
    float: left;
    width: 16px;
  }

  @media (min-width: ${smallDesktopWidth}) {
    font-size: 10px;
  }
  @media (min-width: ${mediumDesktopExactWidth}) {
    font-size: 11px;
  }
`

export const StyledButtonText = styled.div`
  width: 180px;
  text-align: left;
  margin-left: 12px;
  font-size: 14px;
  color: #181406;
  font-weight: 600;
`

export const CreateAccountDivider = styled(Divider)`
  font-size: 14px !important;
  font-weight: 400;
  line-height: 18.2px;
  text-align: left;
`
export const ClassCodeHeading = styled.div`
  width: 100%;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 18px;
  text-align: left;
`

export const SignUpHeader = styled.div`
  width: 100%;
  text-align: left;

  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  margin: 20px 0px 40px;
`

export const InfoBox = styled(Col)`
  margin-top: 10px;
  font-size: 9px;
  color: white;

  @media (min-width: ${extraDesktopWidthMax}) {
    font-size: 12px;
  }
`

export const InfoIcon = styled(Col)`
  color: rgba(0, 0, 0, 0.44);
  text-align: center;
  padding-top: 4px;
  img {
    width: 14px;
    filter: contrast(2);
  }
`

export const LinkDiv = styled.div`
  margin-bottom: 10px;
  a {
    border-bottom: 2px #2f4151 solid;
    color: #2f4151;
    font-size: 14px;
  }
`

export const BoldText = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`

export const FormBody = styled.div`
  background: white;
  margin-top: 10px;
  width: 100%;
  form {
    .ant-form-item {
      margin-bottom: 5px;
    }
    .ant-input {
      border-radius: 30px;
      width: 100%;
      padding: 22px 48px;
      margin-bottom: 10px;
    }
    .ant-input:focus {
      border: 1px solid ${greenDark2};
    }
    .has-error {
      .ant-form-explain,
      .ant-form-split {
        font-size: 10px;
      }
    }
    .ant-form-item-children {
      width: 100%;
      float: left;
      label,
      a {
        line-height: normal;
        font-size: 10px;
      }
      label {
        float: left;
      }
    }
  }
  .ant-input-affix-wrapper {
    .ant-input-prefix {
      width: 15px;
      margin: -4px 0px -4px 10px;
    }
    .ant-input:not(:first-child) {
      padding-left: 45px;
    }
    .ant-input::placeholder {
      color: #777;
    }
  }

  @media (min-width: ${extraDesktopWidthMax}) {
    h5 {
      font-size: 18px;
    }
    form .ant-form-item-label label {
      font-size: 14px;
    }
  }
`

export const RegisterButton = styled(Button)`
  width: 100%;
  background: ${themeColor};
  border-color: ${themeColor};
  border-radius: 30px;
  padding: 22px 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 16px;
  &:hover,
  &:focus {
    border-color: ${themeColor};
    background: ${themeColor};
  }
  margin-top: ${({ $mt }) => $mt || null};
`

export const CircleDiv = styled.div`
  height: ${({ size }) => size || 0}px;
  width: ${({ size }) => size || 0}px;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  bottom: ${({ bottom }) => bottom}px;
  right: ${({ right }) => right}px;
  background: transparent linear-gradient(38deg, #00b0ff 0%, #1fe3a1 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 50%;
  position: fixed;
  opacity: 0.6;
  z-index: 0;
`

export const Description = styled.p`
  text-align: center;
  margin-bottom: 16px;
  font-family: Open Sans, SemiBold;
`

export const MobileViewLinks = styled(Col)`
  margin-top: 20px;
  text-align: center;
  display: none;
  @media (max-width: ${mobileWidthMax}) {
    display: block;
  }
`

export const DesktopVieLinks = styled.div`
  font-family: Open Sans;
  font-weight: 600;
  a {
    font-size: 10px;
    @media (min-width: ${mediumDesktopExactWidth}) {
      font-size: 12px;
    }
    @media (min-width: ${extraDesktopWidthMax}) {
      font-size: 14px;
    }
  }
  @media (max-width: ${mobileWidthMax}) {
    display: none;
  }
`

export const DesktopViewCopyright = styled(Row)`
  width: 50%;
  text-align: start;
  color: ${grey};
  margin: 25px 0px;
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 10px;
  @media (min-width: ${mediumDesktopExactWidth}) {
    font-size: 11px;
  }
`
export const TermsPrivacy = styled.p`
  margin-top: 4px;
  line-height: 1.8;
  font-size: 9px;
  text-align: ${(props) => (props.align ? props.align : 'center')};
  @media (min-width: ${largeDesktopWidth}) {
    min-width: ${({ minWidth }) => minWidth || ' '}};
  }
`

export const ContainerForButtonAtEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px;
  width: ${(props) => props.containerWidth || 'auto'};
  padding-right: ${(props) => props.pR || '0px'};
  margin-top: ${(props) => props.mT || '0px'};
  margin-bottom: ${(props) => props.mB || '0px'};
`

export const PsiContainer = styled.div`
  width: ${({ width }) => width || '83%'};
  margin: 0 auto;
  margin-bottom: 10px;
  hr {
    display: none;
  }
`
