/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconSIIACodie = (props) => (
  <SVG
    data-testid="codie-logo"
    width="400"
    height="126"
    viewBox="0 0 400 126"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5999 33.6002C8.53323 51.8668 2.3999 67.7335 1.86656 69.0668C1.06656 71.2002 1.73323 71.4668 7.33323 71.0668L13.5999 70.6668L26.1332 38.6668C42.3999 -2.6665 41.5999 0.666832 34.3999 0.666832H28.5332L15.5999 33.6002Z"
      fill="white"
    />
    <path
      d="M52.3998 2.93349C51.9998 4.26682 45.9998 19.7335 39.0665 37.2002C32.2665 54.8002 26.6665 69.6002 26.6665 70.2668C26.6665 70.8002 29.3332 71.3335 32.3998 71.3335H38.2665L51.0665 38.2668C58.1332 20.1335 64.2665 4.26682 64.7998 2.93349C65.4665 0.933495 64.7998 0.666824 59.4665 0.666824C54.7998 0.666824 53.0665 1.20016 52.3998 2.93349Z"
      fill="white"
    />
    <path
      d="M83.3332 1.33343C82.6665 1.6001 80.3998 2.13343 78.3998 2.53342C71.5998 4.00009 70.6665 8.40009 70.6665 37.2001C70.6665 62.8001 70.6665 63.2001 73.8665 66.9334C77.0665 70.6668 77.3332 70.6668 94.6665 71.0668L112.133 71.6001L111.733 60.4001L111.333 49.3334L106.4 48.9334L101.467 48.5334L101.067 54.9334L100.667 61.3334L93.7332 61.7334C81.1998 62.4001 81.3332 62.6668 81.3332 36.9334C81.3332 24.6668 81.8665 13.8668 82.5332 12.9334C83.1998 11.8668 87.1998 10.8001 91.8665 10.4001L99.9998 9.60009V15.8668V22.0001H105.333H110.667V11.3334V0.666763L97.7332 0.800095C90.5332 0.800095 84.1332 1.06676 83.3332 1.33343Z"
      fill="white"
    />
    <path
      d="M133.067 2.80016C131.067 3.86683 128.8 5.86683 128 7.3335C127.2 8.80016 126.667 20.9335 126.667 36.6668V63.6002L130.533 67.4668C134.267 71.2002 134.933 71.3335 147.333 71.3335C162.133 71.3335 166.933 69.6002 169.2 63.2002C171.067 57.7335 171.067 16.4002 169.2 9.60016C168.133 5.60016 166.8 4.00016 163.333 2.53349C157.2 0.000160217 137.867 0.133492 133.067 2.80016ZM157.067 11.6002C158.133 12.6668 158.667 20.0002 158.667 36.0002C158.667 52.0002 158.133 59.3335 157.067 60.4002C156.133 61.3335 152.267 62.0002 148.533 62.0002C137.333 62.0002 137.067 61.6002 137.6 35.0668C137.867 16.8002 138.4 12.2668 140 11.3335C142.933 9.46683 155.2 9.73349 157.067 11.6002Z"
      fill="white"
    />
    <path
      d="M185.067 1.4668C184.4 2.00013 184 4.13346 184 6.13346C184 8.93346 184.667 9.86679 187.067 10.2668C190 10.6668 190 10.8001 190 36.0001C190 61.2001 190 61.3335 187.067 61.7335C184.533 62.1335 184 63.0668 184 66.8001V71.4668L204.667 71.0668C225.2 70.6668 225.333 70.6668 228.933 66.9335L232.667 63.3335V36.0001V8.66679L228.933 5.0668C225.333 1.33346 225.067 1.33346 205.6 0.933456C194.8 0.666794 185.6 0.933456 185.067 1.4668ZM220.4 12.5335C222.8 14.9335 222.533 56.9335 220.133 59.7335C218.8 61.4668 216.133 62.0001 209.867 62.0001H201.333V35.8668V9.60013L210.133 10.4001C214.933 10.6668 219.6 11.7335 220.4 12.5335Z"
      fill="white"
    />
    <path
      d="M253.2 3.46699C251.066 7.46699 253.6 12.667 257.733 12.667C261.6 12.667 264 10.4003 264 6.66699C264 0.666993 256 -1.73301 253.2 3.46699Z"
      fill="white"
    />
    <path
      d="M284.933 1.46683C284.4 2.00016 284 4.13349 284 6.26682C284 9.06683 284.667 10.0002 286.667 10.0002C289.2 10.0002 289.333 10.9335 289.333 36.0002C289.333 61.0668 289.2 62.0002 286.667 62.0002C284.533 62.0002 284 62.9335 284 66.6668V71.3335H307.333H330.667V60.0002V48.6668H326.133C321.6 48.6668 321.467 48.8002 321.067 54.9335L320.667 61.3335L311.067 61.7335L301.333 62.1335V51.3335V40.6668H311.333H321.333V35.6002V30.6668L311.333 31.0668L301.333 31.6002V20.8002V10.0002H310.667H320V16.0002V22.0002H325.333H330.8L330.4 11.6002L330 1.3335L307.867 0.933495C295.733 0.800156 285.467 1.06683 284.933 1.46683Z"
      fill="white"
    />
    <path
      d="M350 34.9335C342.667 53.7335 336.4 69.6002 336.133 70.2669C336 70.9335 338.667 71.3335 342.133 71.0669L348.533 70.6669L360.267 40.6669C366.667 24.1335 372.933 8.40019 374 5.60019L376 0.666855H369.6H363.333L350 34.9335Z"
      fill="white"
    />
    <path
      d="M374.8 35.2002C367.466 54.2668 361.333 70.1335 361.333 70.5335C361.333 70.9335 364 71.3335 367.2 71.3335H373.066L383.333 44.9335C389.066 30.5335 395.066 15.0668 396.8 10.8002C398.533 6.40016 400 2.40016 400 1.73349C400 1.20016 397.333 0.666824 394.133 0.666824H388.4L374.8 35.2002Z"
      fill="white"
    />
    <path
      d="M248 25.3335C248 29.4668 248.4 30.0002 251.333 30.0002H254.667V46.0002V62.0002H251.333C248.4 62.0002 248 62.5335 248 66.6668V71.3335H260H272V66.6668C272 62.5335 271.6 62.0002 268.667 62.0002H265.333V41.3335V20.6668H256.667H248V25.3335Z"
      fill="white"
    />
    <path
      d="M5.99981 90.1337C2.13314 92.4003 1.33314 94.0003 1.33314 99.467C1.33314 104.8 3.86647 107.867 10.2665 110.134C14.7998 111.6 16.3998 114.134 15.1998 117.334C14.1331 120.134 10.9331 119.867 9.06647 116.667C7.99981 115.067 5.86647 114 3.59981 114C0.266474 114 -0.000193149 114.4 0.666474 116.934C2.13314 121.734 6.39981 124.667 12.2665 124.667C20.7998 124.667 26.1331 117.2 23.1998 109.334C22.6665 108.134 19.4665 105.734 15.8665 103.867C11.4665 101.734 9.33314 99.7337 9.33314 98.0003C9.33314 94.1337 12.2665 93.2003 14.3998 96.4003C17.0665 100.534 22.6665 100.4 22.6665 96.1337C22.6665 94.4003 21.7331 92.267 20.6665 91.3337C17.8665 89.067 9.06647 88.4003 5.99981 90.1337Z"
      fill="white"
    />
    <path
      d="M98.1332 91.7337C94.2666 95.6003 92.7999 103.6 93.9999 112.8C95.1999 121.334 98.2666 124.667 105.2 124.667C111.867 124.667 115.867 121.334 116.933 114.934C117.6 110.134 117.6 110 113.467 110C110.133 110 109.333 110.534 109.333 112.934C109.333 117.2 107.333 119.867 104.8 118.934C103.067 118.267 102.667 115.867 102.667 106.8C102.667 100.4 103.333 95.067 104 94.667C106 93.467 109.333 96.5337 109.333 99.6003C109.333 101.6 110.133 102.134 113.067 101.734C116.133 101.334 116.667 100.8 116.4 97.3337C115.733 89.067 104.267 85.6003 98.1332 91.7337Z"
      fill="white"
    />
    <path
      d="M125.6 90.0002C121.467 91.7335 120 96.5335 120 108.133C120 116.933 120.4 118.533 123.333 121.333C126.8 124.933 134 125.867 138.667 123.333C143.467 120.8 145.733 104.933 142.667 95.7335C140.4 89.6002 132.933 87.0668 125.6 90.0002ZM135.067 99.4668C136.533 104.8 135.333 116.533 133.333 118.533C130.667 121.2 128 115.467 128 106.933C128 94.4002 132.533 89.6002 135.067 99.4668Z"
      fill="white"
    />
    <path
      d="M176 92C176 95.0667 176.533 95.4667 179.733 95.0667C182.267 94.8 183.333 93.8667 183.333 92C183.333 90.1334 182.267 89.2 179.733 88.9334C176.533 88.5334 176 88.9334 176 92Z"
      fill="white"
    />
    <path
      d="M373.333 90.4003C371.6 91.3337 369.6 93.867 368.8 96.267C367.333 101.734 370.133 106.4 377.067 109.734C380.8 111.467 382 112.8 382 115.334C382 119.6 377.467 120.8 376.4 116.8C375.333 112.934 368 112.8 368 116.667C368 121.334 372.667 124.667 379.2 124.667C386.933 124.667 390.933 121.467 391.067 115.067C391.2 109.334 389.333 106.667 382.533 103.2C376.267 100 375.2 98.267 377.733 95.7337C380 93.467 382.667 94.4003 382.667 97.467C382.667 98.667 384.133 99.3337 386.667 99.3337C389.6 99.3337 390.667 98.667 390.667 96.9337C390.533 90.1337 381.067 86.5337 373.333 90.4003Z"
      fill="white"
    />
    <path
      d="M28 107.334V124.667H32H36V107.334V90.0003H32H28V107.334Z"
      fill="white"
    />
    <path
      d="M41.333 107.334V124.667H45.333H49.333V107.334V90.0003H45.333H41.333V107.334Z"
      fill="white"
    />
    <path
      d="M56.7997 105.6C54.6664 114.267 52.6664 122.134 52.2664 123.067C51.8664 124.4 52.7997 124.8 56.133 124.4C59.5997 124.134 60.7997 123.334 61.0664 120.934C61.4664 118.534 62.3997 118 65.9997 118C69.5997 118 70.533 118.534 70.933 120.934C71.1997 123.2 72.3997 124.134 75.0664 124.4C79.5997 124.934 79.5997 124.134 74.933 105.334L71.1997 90.667L65.8664 90.267L60.533 89.867L56.7997 105.6ZM67.8664 108.267C67.9997 110.534 67.1997 111.334 65.1997 111.334C63.1997 111.334 62.6664 110.667 63.1997 108.934C63.4664 107.734 64.2664 104.534 64.7997 102L65.8664 97.3337L66.7997 101.334C67.333 103.467 67.733 106.667 67.8664 108.267Z"
      fill="white"
    />
    <path
      d="M148 107.467V125.067L156.933 124.267C164 123.734 166.4 122.934 168.267 120.8C173.067 114.8 172.8 97.4669 167.867 92.5336C165.867 90.5336 163.333 90.0002 156.667 90.0002H148V107.467ZM162.933 100.934C163.467 103.6 163.467 108.4 162.933 111.6C162.133 116.134 161.333 117.467 158.933 117.734C156.133 118.134 156 117.867 156 106.667C156 95.6002 156.133 95.2002 158.933 95.6002C161.2 95.8669 162.133 97.2002 162.933 100.934Z"
      fill="white"
    />
    <path
      d="M188.267 106.933L188.667 124H198.667C208 124 208.667 123.733 209.067 121.067C209.467 118.4 208.8 118.133 203.067 117.733C197.2 117.333 196.667 117.067 196.267 113.733C195.867 110.4 196.267 110.133 201.6 109.733C206.267 109.467 207.467 108.8 207.733 106.4C208.133 103.867 207.467 103.467 202.4 103.067C197.6 102.8 196.533 102.133 196.267 99.6C195.867 96.9333 196.4 96.6667 201.867 96.6667C207.467 96.6667 208 96.4 208 93.3333C208 90.1333 207.733 90 198 90H187.867L188.267 106.933Z"
      fill="white"
    />
    <path
      d="M226.8 106.534C224.533 115.734 222.667 123.6 222.667 124C222.667 124.4 224.4 124.667 226.667 124.667C229.733 124.667 230.8 124 231.467 121.2C232.133 118.4 232.933 117.867 236.533 118.267C239.6 118.534 240.8 119.334 241.067 121.6C241.467 124 242.4 124.667 245.467 124.667C250.267 124.667 250.267 124.134 245.6 105.334L241.867 90.667L236.4 90.267L231.067 89.867L226.8 106.534ZM237.867 104.934C238.8 110.8 238.667 111.334 236 111.334C233.467 111.334 233.333 110.8 234 106.267C235.733 95.867 236.533 95.6003 237.867 104.934Z"
      fill="white"
    />
    <path
      d="M251.6 106.934C253.2 116.4 254.667 124.134 254.667 124.4C254.667 124.667 256.667 124.667 259.067 124.4C263.2 124 263.333 123.734 264.4 116.667C265.067 112.667 265.867 107.867 266.267 106C267.067 102.534 267.067 102.4 270 120.267C270.667 124 271.333 124.667 274.667 124.667C276.8 124.667 278.8 124.134 279.2 123.6C279.467 123.067 280.8 116.934 282 110C283.067 103.067 284.4 95.7337 284.8 93.6003C285.467 90.267 285.2 90.0003 281.6 90.0003C278.133 90.0003 277.6 90.5337 276.8 94.9337C276.4 97.7337 276 101.867 276 104.267C276 111.867 273.733 110.134 272.8 101.867C271.6 91.067 270.933 90.0003 266.667 90.0003C263.067 90.0003 262.933 90.267 262 98.267C260.4 112.267 259.067 113.067 258 100.934L257.067 90.0003H252.8H248.4L251.6 106.934Z"
      fill="white"
    />
    <path
      d="M288.933 104.267C286.933 112.134 284.933 120 284.533 121.734C283.733 124.667 284 124.8 288.133 124.4C291.6 124.134 292.8 123.334 293.066 120.934C293.466 118.534 294.4 118 298 118C301.6 118 302.533 118.534 302.933 120.934C303.2 123.2 304.4 124.134 307.066 124.4C311.6 124.934 311.6 124.134 306.933 105.334L303.2 90.667L297.866 90.267L292.666 89.867L288.933 104.267ZM299.866 108.267C300 110.534 299.2 111.334 297.333 111.334C294.533 111.334 294.533 110.934 296.933 100.934L297.733 97.3336L298.666 101.334C299.2 103.467 299.733 106.667 299.866 108.267Z"
      fill="white"
    />
    <path
      d="M314.667 107.467V124.8L318.4 124.4C321.733 124 322 123.467 322.4 116.934C322.8 111.067 323.2 110 325.467 110C327.467 110 328 110.934 328 114.267C328 122.4 329.333 124.667 333.733 124.667H337.733L336.933 117.2C336.533 113.2 335.6 109.067 334.8 108.267C334 107.2 334.133 105.867 335.467 104.134C337.867 100.667 337.867 96.667 335.2 92.9336C333.467 90.4003 332 90.0003 323.867 90.0003H314.667V107.467ZM327.733 96.9336C329.867 99.067 329.733 100 327.2 102.534C324 105.867 322.667 105.067 322.667 100C322.667 95.2003 324.667 93.867 327.733 96.9336Z"
      fill="white"
    />
    <path
      d="M341.6 106.933L342 124H350.266C357.2 124 359.2 123.467 361.6 120.933C364.266 118.267 364.666 116.533 364.666 106.8C364.666 91.8667 363.066 90 350.4 90H341.2L341.6 106.933ZM356 98.8C359.066 106.667 356.666 118 352 118C349.6 118 349.333 117.2 349.333 107.6C349.333 101.867 349.733 96.6667 350.266 96.2667C352.133 94.2667 354.8 95.4667 356 98.8Z"
      fill="white"
    />
    <path
      d="M176 111.467V124.8L179.733 124.4L183.333 124L183.733 110.934L184.133 98.0003H180H176V111.467Z"
      fill="white"
    />
  </SVG>
)

export default withIconStyles(IconSIIACodie)
