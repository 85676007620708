import React from 'react'
import { IconSIIACodie } from '@edulastic/icons'

import { BannerContainer, InfoContainer } from './styled'

export const CodieBanner = (props) => {
  const { bgColor, borderColor, currentPage = 'login' } = props
  const isLoginPage = currentPage.includes('login')
  return (
    <BannerContainer
      bgColor={bgColor}
      borderColor={borderColor}
      $isLoginPage={isLoginPage}
    >
      <IconSIIACodie width="110" height="70" />
      <InfoContainer>🏆 Best Formative Assessment Platform</InfoContainer>
    </BannerContainer>
  )
}
