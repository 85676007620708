import {
  accessibilityColors,
  mediumDesktopExactWidth,
  smallDesktopWidth,
} from '@edulastic/colors'
import styled from 'styled-components'

export const BannerContainer = styled.div`
  padding: 0px 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${accessibilityColors.banner.purple.bg};
  border: 1px solid ${accessibilityColors.banner.purple.border};
  border-radius: 4px;
  opacity: 0px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
  gap: 15px;
  margin-bottom: ${({ $isLoginPage }) => ($isLoginPage ? '0px' : '30px')};
  @media (max-width: ${mediumDesktopExactWidth}) and (min-width: ${smallDesktopWidth}) {
    padding: 10px;
  }
`

export const InfoContainer = styled.div`
  text-align: center;
`
