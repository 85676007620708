/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconMail = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17.648 13.867"
    {...props}
  >
    <defs />
    <g transform="translate(0 -59.014)">
      <g transform="translate(0 59.013)">
        <path
          className="a"
          d="M32.476,328.916a1.508,1.508,0,0,0,1.033-.39l-5-5-.346.249q-.561.414-.911.645a5.614,5.614,0,0,1-.931.473,2.836,2.836,0,0,1-1.083.241h-.02a2.836,2.836,0,0,1-1.083-.241,5.593,5.593,0,0,1-.931-.473q-.35-.231-.911-.645l-.345-.25-5,5a1.509,1.509,0,0,0,1.033.39Z"
          transform="translate(-16.403 -315.05)"
        />
        <path
          className="a"
          d="M.995,199.412A5.221,5.221,0,0,1,0,198.555v7.608l4.407-4.407Q3.085,200.832.995,199.412Z"
          transform="translate(0 -194.084)"
        />
        <path
          className="a"
          d="M416.729,199.412q-2.012,1.362-3.42,2.345l4.405,4.405v-7.608A5.437,5.437,0,0,1,416.729,199.412Z"
          transform="translate(-400.066 -194.084)"
        />
        <path
          className="a"
          d="M16.078,59.013H1.582a1.406,1.406,0,0,0-1.167.512,1.987,1.987,0,0,0-.409,1.28A2.3,2.3,0,0,0,.548,62.15,4.886,4.886,0,0,0,1.7,63.287q.335.237,2.019,1.4c.606.42,1.133.786,1.586,1.1.386.269.719.5.994.7l.147.1.272.2q.32.231.532.374t.512.32a2.988,2.988,0,0,0,.566.266,1.567,1.567,0,0,0,.492.089h.02a1.568,1.568,0,0,0,.492-.089,2.981,2.981,0,0,0,.566-.266q.3-.177.512-.32t.532-.374l.271-.2.147-.1,1-.693,3.612-2.508a4.684,4.684,0,0,0,1.2-1.211,2.606,2.606,0,0,0,.482-1.487,1.589,1.589,0,0,0-1.576-1.576Z"
          transform="translate(-0.006 -59.013)"
        />
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconMail)
